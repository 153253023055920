document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.media_file[data-error-icon]').forEach((image) => {
        image.addEventListener('error', () => {
            const alt = image.hasAttribute('alt') ? image.getAttribute('alt') : '';
            if (image.dataset.removeOnError === 'true') {
                if (alt !== '') {
                    image.outerHTML = alt;
                } else {
                    image.outerHTML = '';
                }
            } else {
                image.outerHTML = '<span class="far text-2xl ' + image.dataset.errorIcon + ' text-primary"></span>';
                if (alt !== '') {
                    image.insertAdjacentHTML('afterend', alt);
                }
            }
        });
    });
});
